export const getApiBaseUrl = () => {

 // let apiUrl = 'https://cinderbuild-backend.herokuapp.com'

  // let apiUrl = 'https://cinderbuild-backend-dev-xh9or.ondigitalocean.app'

  // let apiUrl = 'https://cinderbuild-backend-dev-xh9or.ondigitalocean.app'

  // let apiUrl = 'https://cinderbuild-backend-dev-xh9or.ondigitalocean.app'  

  // let apiUrl = 'https://cinderbuild-backend.herokuapp.com'
    let apiUrl = 'https://octopus-app-tcqnl.ondigitalocean.app'
 //  let apiUrl = 'https://cinderbuild-backend-dev-xh9or.ondigitalocean.app'  

 // let apiUrl = 'http://localhost:3001'
  // 'https://cinderbuild-backend-dev.herokuapp.com'
  // 'https://cinderbuild-backend-dev.herokuapp.com'

  return apiUrl
}
